import React, { useState } from "react";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import Layout from "@components/layout";
import Seo from "@components/seo";
import TopNav from "@components/topNav";
import Footer from "@components/footer";
import { CheckIcon } from "@heroicons/react/outline";

/*

partnerName - required
partnerShortText  - required
partnerAboutpartnershipHeading - optional
partnerAboutPartnershipText - required
partnerQuote - optional
partberAboutHeading - optional
partberAbouttext - required
partnerLogo - required

*/

const features = [
  {
    name: "Data light via web or WhatsApp",
    description:
      "Boost is optimised for low bandwith and requires minimal data",
  },
  {
    name: "Free to download for offline use",
    description:
      "Ornare donec rhoncus vitae nisl velit, neque, mauris dictum duis. Nibh urna non parturient.",
  },
  {
    name: "Available to individuals and organisations",
    description:
      "Etiam cras augue ornare pretium sit malesuada morbi orci, venenatis. Dictum lacus.",
  },
  {
    name: "Regularly updated with the latest information.",
    description:
      "Interdum quam pulvinar turpis tortor, egestas quis diam amet, natoque. Mauris sagittis.",
  },
];

const NewsPage = () => {
  const results = [
    {
      title: "BoostBot launches on WhatsApp",
      href: "#",
      category: { name: "Article", href: "#" },
      description:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Architecto accusantium praesentium eius, ut atque fuga culpa, similique sequi cum eos quis dolorum.",
      date: "Mar 16, 2020",
      datetime: "2020-03-16",
      imageUrl: "hero-2.jpeg",
      readingTime: "6 min",
      author: {
        name: "Roel Aufderehar",
        href: "#",
        imageUrl:
          "https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
      },
    },
    {
      title: "Be In the Know new services",
      href: "#",
      category: { name: "Video", href: "#" },
      description:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Velit facilis asperiores porro quaerat doloribus, eveniet dolore. Adipisci tempora aut inventore optio animi., tempore temporibus quo laudantium.",
      date: "Mar 10, 2020",
      datetime: "2020-03-10",
      imageUrl: "hero-11.jpeg",
      readingTime: "4 min",
      author: {
        name: "Brenna Goyette",
        href: "#",
        imageUrl:
          "https://images.unsplash.com/photo-1550525811-e5869dd03032?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
      },
    },
    {
      title: "Life skills app Mozambique",
      href: "#",
      category: { name: "Case Study", href: "#" },
      description:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Sint harum rerum voluptatem quo recusandae magni placeat saepe molestiae, sed excepturi cumque corporis perferendis hic.",
      date: "Feb 12, 2020",
      datetime: "2020-02-12",
      imageUrl: "hero-10.jpeg",
      readingTime: "11 min",
      author: {
        name: "Daniela Metz",
        href: "#",
        imageUrl:
          "https://images.unsplash.com/photo-1487412720507-e7ab37603c6f?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
      },
    },
    {
      title: "BoostBot launches on WhatsApp",
      href: "#",
      category: { name: "Article", href: "#" },
      description:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Architecto accusantium praesentium eius, ut atque fuga culpa, similique sequi cum eos quis dolorum.",
      date: "Mar 16, 2020",
      datetime: "2020-03-16",
      imageUrl: "hero-2.jpeg",
      readingTime: "6 min",
      author: {
        name: "Roel Aufderehar",
        href: "#",
        imageUrl:
          "https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
      },
    },
    {
      title: "Be In the Know new services",
      href: "#",
      category: { name: "Video", href: "#" },
      description:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Velit facilis asperiores porro quaerat doloribus, eveniet dolore. Adipisci tempora aut inventore optio animi., tempore temporibus quo laudantium.",
      date: "Mar 10, 2020",
      datetime: "2020-03-10",
      imageUrl: "hero-11.jpeg",
      readingTime: "4 min",
      author: {
        name: "Brenna Goyette",
        href: "#",
        imageUrl:
          "https://images.unsplash.com/photo-1550525811-e5869dd03032?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
      },
    },
    {
      title: "Life skills app Mozambique",
      href: "#",
      category: { name: "Case Study", href: "#" },
      description:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Sint harum rerum voluptatem quo recusandae magni placeat saepe molestiae, sed excepturi cumque corporis perferendis hic.",
      date: "Feb 12, 2020",
      datetime: "2020-02-12",
      imageUrl: "hero-10.jpeg",
      readingTime: "11 min",
      author: {
        name: "Daniela Metz",
        href: "#",
        imageUrl:
          "https://images.unsplash.com/photo-1487412720507-e7ab37603c6f?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
      },
    },
    {
      title: "BoostBot launches on WhatsApp",
      href: "#",
      category: { name: "Article", href: "#" },
      description:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Architecto accusantium praesentium eius, ut atque fuga culpa, similique sequi cum eos quis dolorum.",
      date: "Mar 16, 2020",
      datetime: "2020-03-16",
      imageUrl: "hero-2.jpeg",
      readingTime: "6 min",
      author: {
        name: "Roel Aufderehar",
        href: "#",
        imageUrl:
          "https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
      },
    },
    {
      title: "Be In the Know new services",
      href: "#",
      category: { name: "Video", href: "#" },
      description:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Velit facilis asperiores porro quaerat doloribus, eveniet dolore. Adipisci tempora aut inventore optio animi., tempore temporibus quo laudantium.",
      date: "Mar 10, 2020",
      datetime: "2020-03-10",
      imageUrl: "hero-11.jpeg",
      readingTime: "4 min",
      author: {
        name: "Brenna Goyette",
        href: "#",
        imageUrl:
          "https://images.unsplash.com/photo-1550525811-e5869dd03032?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
      },
    },
    {
      title: "Life skills app Mozambique",
      href: "#",
      category: { name: "Case Study", href: "#" },
      description:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Sint harum rerum voluptatem quo recusandae magni placeat saepe molestiae, sed excepturi cumque corporis perferendis hic.",
      date: "Feb 12, 2020",
      datetime: "2020-02-12",
      imageUrl: "hero-10.jpeg",
      readingTime: "11 min",
      author: {
        name: "Daniela Metz",
        href: "#",
        imageUrl:
          "https://images.unsplash.com/photo-1487412720507-e7ab37603c6f?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
      },
    },
  ];

  return (
    <div>
      <div className="relative bg-boostPink overflow-hidden">
        <div className="max-w-6xl mx-auto">
          <div className="relative z-10 pb-8 bg-boostPink sm:pb-16 md:pb-20 lg:max-w-2xl lg:w-full lg:pb-28 xl:pb-32">
            <svg
              className="hidde pl-20 lg:block absolute right-0 inset-y-0 h-full w-56 text-boostPink transform translate-x-1/2"
              fill="currentColor"
              viewBox="0 0 180 585"
              preserveAspectRatio="none"
              aria-hidden="true"
            >
              <g
                id="Page-1"
                stroke="none"
                strokeWidth="1"
                fill="none"
                fillRule="evenodd"
              >
                <polygon
                  id="Path"
                  fill="#BD7AB2"
                  fillRule="nonzero"
                  points="0.296038107 585 58.6111495 585 179.266137 296.235761 60.0246807 0 0.296038107 0"
                ></polygon>
              </g>
            </svg>

            <TopNav theme={"sunset"} />

            <main className="mt-10 mx-auto max-w-7xl px-4 sm:mt-12 sm:px-6 md:mt-16 lg:mt-20 lg:px-8 xl:mt-28">
              <div className="sm:text-center lg:text-left pb-16">
                {" "}
                {/* Padding botom if no video button */}
                <h1 className="text-4xl tracking-tight font-extrabold text-midBlue sm:text-5xl md:text-6xl font-heading">
                  Vitol Foundation
                  {/* <span className="block text-indigo-600 xl:inline"></span> */}
                </h1>
                <p className="mt-3 text-base text-white sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-xl lg:mx-0">
                  Vitol foundation has been a donor of Avert since 2019 enabling
                  the creation and adaption of our Boost app
                </p>
                <div className="mt-4 sm:mt-8 sm:flex sm:justify-center lg:justify-start">
                  {/* <div className="rounded-md shadow">
                  <a
                    href="#"
                    className="w-full flex items-center justify-center px-8 py-3 text-base font-medium rounded-md text-midBlue bg-yellow hover:bg-indigo-700 md:py-4 md:text-lg md:px-10"
                  >
                    Our projects
                  </a>
                </div> */}
                  {/* <div className="">
                    <button
                      href="#"
                      className="w-full flex items-center justify-center px-8 py-3 text-base text-midBlue font-medium rounded-md  bg-white hover:bg-indigo-200 md:py-4 md:text-lg md:px-10"
                    >
                      Watch video
                      <IoPlay
                        className="inline-block w-7 h-7 pl-1"
                        aria-hidden="true"
                      />
                    </button>
                  </div> */}
                </div>
                {/* <p className="mt-3 text-base text-white sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-xl lg:mx-0">
                Avert is using the power of digital communications to increase
                knowledge and understanding of HIV and sexual health, so people
                can make informed choices and live healthily.
              </p> */}
              </div>
            </main>
          </div>
        </div>
        <div className="lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2">
          <StaticImage
            src="../images/hero-boost.jpeg"
            className="h-56 w-full object-cover sm:h-72 md:h-96 lg:w-full lg:h-full"
            alt="Avert"
            placeholder="blurred"
            layout="fullWidth"
            // width={1748}
            // height={1327}
          />
        </div>
      </div>
      <div className="">
        <div className="relative py-16 bg-white overflow-hidden">
          <div className="hidden lg:block lg:absolute lg:inset-y-0 lg:h-full lg:w-full"></div>
          <div className="relative px-4 sm:px-6 lg:px-8">
            <div className="text-lg max-w-prose mx-auto">
              {/* <h1>

                <span className="mt-2 block text-3xl text-center leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl font-heading">
                  About the partnership
                </span>
              </h1> */}
              <p className="mt-3 text-xl text-fossil leading-8">
                Vitol foundation has been a donor of Avert since 2019 enabling
                the creation and adaption of our Boost app, which provides
                digital tools and resources for community health workers in
                Southern Africa. Vitol has also funded an evaluation of our
                Young Voices project in Zambia.
              </p>
              <p className="mt-8 text-xl text-fossil leading-8">
                The Boost app was launched in January 2020 after being developed
                with community health workers in Southern Africa to give them
                relevant and accurate digital information and tools to use in
                their work. The aim of the project is to help community health
                workers keep learning, and equip them with fun, interactive
                tools that they can use with their clients.
              </p>
            </div>
            {/* <div className="mt-14 prose prose-indigo prose-lg text-fossil mx-auto">
              <figure>
                <img
                  className="w-full rounded-lg"
                  src="vitol.png"
                  alt="Boost screens"
                />

              </figure>
            </div> */}
          </div>
        </div>
      </div>

      <div className="relative bg-boostPink pt-10 pb-6 px-4 sm:px-6 lg:pt-10 lg:pb-6 lg:px-8  border-t-8 border-b-8 border-white">
        <div className="relative max-w-7xl mx-auto">
          <div className="mt-12 lg:m-0 lg:col-span-2 lg:pl-8">
            <div className="flex v-screen">
              <div className="m-auto">
                {/* <svg
                  className="h-12 w-12 text-sunset flex v-screen"
                  fill="currentColor"
                  // viewBox="0 0 32 32"
                  aria-hidden="true"
                >
                  <path d="M9.352 4C4.456 7.456 1 13.12 1 19.36c0 5.088 3.072 8.064 6.624 8.064 3.36 0 5.856-2.688 5.856-5.856 0-3.168-2.208-5.472-5.088-5.472-.576 0-1.344.096-1.536.192.48-3.264 3.552-7.104 6.624-9.024L9.352 4zm16.512 0c-4.8 3.456-8.256 9.12-8.256 15.36 0 5.088 3.072 8.064 6.624 8.064 3.264 0 5.856-2.688 5.856-5.856 0-3.168-2.304-5.472-5.184-5.472-.576 0-1.248.096-1.44.192.48-3.264 3.456-7.104 6.528-9.024L25.864 4z" />
                </svg> */}
                <div className="flex v-screen">
                  <div className="m-auto">
                    <svg
                      className="h-12 w-12 text-midBlue flex v-screen p-2"
                      viewBox="0 0 227 212"
                      version="1.1"
                    >
                      <path
                        d="M97.714,10.08 L97.714,47.947 C97.714,53.084 93.55,57.251 88.41,57.251 C70.084,57.251 60.121,76.041 58.741,113.131 L88.41,113.131 C93.55,113.131 97.714,117.293 97.714,122.436 L97.714,202.39 C97.714,207.527 93.55,211.688 88.41,211.688 L9.304,211.688 C4.164,211.688 0,207.527 0,202.39 L0,122.436 C0,104.656 1.791,88.331 5.32,73.92 C8.941,59.154 14.496,46.239 21.826,35.535 C29.374,24.541 38.817,15.908 49.893,9.897 C61.041,3.851 74,0.782 88.416,0.782 C93.55,0.782 97.714,4.947 97.714,10.08 Z"
                        fill="#17607D"
                        fillRule="nonzero"
                      ></path>
                      <path
                        d="M217.646,0.782 C203.235,0.782 190.27,3.85 179.123,9.897 C168.046,15.908 158.603,24.541 151.056,35.535 C143.721,46.245 138.17,59.154 134.544,73.931 C131.015,88.341 129.23,104.655 129.23,122.435 L129.23,202.389 C129.23,207.526 133.395,211.687 138.528,211.687 L217.641,211.687 C222.774,211.687 226.938006,207.526 226.938006,202.389 L226.938006,122.435 C226.938006,117.292 222.774,113.13 217.641,113.13 L188.389,113.13 C189.751,76.04 199.575,57.25 217.641,57.25 C222.774,57.25 226.938006,53.083 226.938006,47.946 L226.938006,10.08 C226.944,4.947 222.78,0.782 217.646,0.782 Z"
                        fill="#17607D"
                        fillRule="nonzero"
                      ></path>
                    </svg>
                  </div>
                </div>
                <blockquote>
                  <p className="mb-3 text-2xl font-medium text-white mt-3 text-center  max-w-prose">
                    The app has made it easy for peer educators to share correct
                    information with their clients.
                  </p>
                </blockquote>
                <div className="flex v-screen">
                  <div className="m-auto">
                    <svg
                      className="h-12 w-12 text-midBlue flex v-screen p-2"
                      viewBox="0 0 227 212"
                      version="1.1"
                    >
                      <path
                        d="M217.904,0.515 L138.795,0.515 C133.659,0.515 129.491,4.679 129.491,9.819 L129.491,89.768 C129.491,94.907 133.652,99.072 138.795,99.072 L168.461,99.072 C167.084,136.162 157.118,154.949 138.795,154.949 C133.659,154.949 129.491,159.113 129.491,164.253 L129.491,202.12 C129.491,207.257 133.652,211.424 138.795,211.424 C153.206,211.424 166.168,208.35 177.316,202.31 C188.387,196.292 197.833,187.671 205.375,176.665 C212.704,165.964 218.261,153.055 221.884,138.281 C225.412,123.87 227.203004,107.551 227.203004,89.768 L227.203004,9.818 C227.208,4.679 223.035,0.515 217.904,0.515 Z"
                        id="Path"
                        fill="#17607D"
                        fillRule="nonzero"
                      ></path>
                      <path
                        d="M88.672,0.515 L9.563,0.515 C4.432,0.515 0.265,4.679 0.265,9.819 L0.265,89.768 C0.265,94.907 4.432,99.072 9.563,99.072 L38.815,99.072 C37.45,136.162 27.627,154.949 9.563,154.949 C4.432,154.949 0.265,159.113 0.265,164.253 L0.265,202.12 C0.265,207.257 4.432,211.424 9.563,211.424 C23.973,211.424 36.941,208.35 48.089,202.31 C59.165,196.292 68.605,187.671 76.147,176.665 C83.489,165.964 89.044,153.055 92.668,138.275 C96.191,123.87 97.977,107.551 97.977,89.775 L97.977,9.818 C97.976,4.679 93.809,0.515 88.672,0.515 Z"
                        id="Path"
                        fill="#17607D"
                        fillRule="nonzero"
                      ></path>
                    </svg>
                    {/* <svg
                      className="h-12 w-12 text-sunset flex v-screen"
                      fill="currentColor"
                      // viewBox="0 0 32 32"
                      aria-hidden="true"
                    >
                      <path d="M9.352 4C4.456 7.456 1 13.12 1 19.36c0 5.088 3.072 8.064 6.624 8.064 3.36 0 5.856-2.688 5.856-5.856 0-3.168-2.208-5.472-5.088-5.472-.576 0-1.344.096-1.536.192.48-3.264 3.552-7.104 6.624-9.024L9.352 4zm16.512 0c-4.8 3.456-8.256 9.12-8.256 15.36 0 5.088 3.072 8.064 6.624 8.064 3.264 0 5.856-2.688 5.856-5.856 0-3.168-2.304-5.472-5.184-5.472-.576 0-1.248.096-1.44.192.48-3.264 3.456-7.104 6.528-9.024L25.864 4z" />
                    </svg> */}
                  </div>
                </div>

                <footer className="mt-6">
                  <p className="text-base font-medium text-white text-center">
                    Vitol
                  </p>
                  {/* <p className="text-base font-medium text-black text-center">
                    Executive Director, UNAIDS
                  </p> */}
                </footer>
              </div>
            </div>
          </div>
          {/* <div className="mt-12 mb-12  max-w-prose  mx-auto ">
       

            <blockquote>
              <p>
                We believe in individuals’ inalienable right to health, and
                their right to make informed choices around their sexual health.
                We aim for everything we do, and how we do it, to be underpinned
                by our set of core values.
              </p>
            </blockquote>
            <p>- Winnie Byanyima, Executive Director, UNAIDS</p>
          </div> */}
        </div>
      </div>

      <div className="">
        <div className="relative py-16 bg-white overflow-hidden">
          <div className="hidden lg:block lg:absolute lg:inset-y-0 lg:h-full lg:w-full"></div>
          <div className="relative px-4 sm:px-6 lg:px-8">
            <div className="text-lg max-w-prose mx-auto">
              {/* <h1>

                <span className="mt-2 block text-3xl text-center leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl font-heading">
                  About the partnership
                </span>
              </h1> */}
              <p className="mt-3 text-xl text-fossil leading-8">
                Vitol Foundation invests in equitable, replicable and scalable
                solutions across the multiple sectors, including health. The
                Foundation seeks to fund organisations and ideas that support
                children and families living in deprivation to escape the cycle
                of poverty and reach their potential in life.
              </p>
            </div>
            <div className="flex pb-12 pt-12">
              <div className="mx-auto">
                <StaticImage
                  src="../images/vitol.png"
                  // className="h-56 w-full object-cover sm:h-72 md:h-96 lg:w-full lg:h-full"
                  alt="Avert"
                  placeholder="blurred"
                  layout="fixed"
                />
              </div>
            </div>
            {/* <div className="mt-14 pb-6  text-fossil">
              <StaticImage
                src="../images/vitol.png"
                // className="h-56 w-full object-cover sm:h-72 md:h-96 lg:w-full lg:h-full"
                alt="Avert"
                placeholder="blurred"
                layout="fixed"
              />
            </div> */}
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default NewsPage;
